import { withStyles } from '@material-ui/core';
import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import styled from 'styled-components';
import arrowRight from './arrow-right.svg';

const tokenizeComponentStack = (stack: string): string[][] => {
	const lines: string[] = stack.split('\n').filter(Boolean);
	return lines.map(line => {
		const tokens = line.split(' (');
		if (tokens.length > 1) {
			tokens[1] = tokens[1].slice(0, Math.max(0, tokens[1].length - 1));
		}

		return tokens;
	});
};

const ArrowButton = styled<{ rotation: number }, 'a'>('a')`
	display: block;
	cursor: pointer;
	padding: 1.5rem 2rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 1rem;
		background: url(${arrowRight}) 0 50% no-repeat;
		background-size: 1rem;
		transform: rotate(${props => props.rotation}deg);
	}
`;

const errorStyle: any = {
	root: {
		padding: '1rem',
		fontWeight: 'normal',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	header: {
		color: '#bc2635'
	},
	content: {
		color: '#333',
		fontFamily: 'Consolas, monospace',
		fontSize: '1.6rem'
	},
	stackLine: {
		padding: '0.5rem 0'
	},
	stackTextSecondary: {
		color: '#888',
		fontSize: '0.9rem'
	}
};

interface FallbackComponentProps {
	classes: any;
	componentStack: string;
	error: { message: string };
}

class FallbackComponent extends React.Component<FallbackComponentProps> {
	public state = { collapsed: true };

	public handleChangeCollapsed = () => {
		this.setState({ collapsed: !this.state.collapsed });
	};

	public render() {
		const { classes, error, componentStack } = this.props;
		const allTokens = tokenizeComponentStack(componentStack);
		const tokens1 = allTokens.slice(0, 1);
		const tokens2 = allTokens.slice(1, allTokens.length);

		const mapTokens = (tokens: string[], index: number) => (
			<div key={index} className={classes.stackLine}>
				<div className={classes.stackTextPrimary}>{tokens[0]}</div>
				<div className={classes.stackTextSecondary}>{tokens[1]}</div>
			</div>
		);

		return (
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<h2 className={classes.header}>{error.message}</h2>
					<div className={classes.content}>
						{tokens1.map(mapTokens)}
						{tokens2.length > 0 && (
							<ArrowButton rotation={this.state.collapsed ? 0 : 90} onClick={this.handleChangeCollapsed}>
								{tokens2.length} stack lines were collapsed
							</ArrowButton>
						)}
						{tokens2.length > 0 && !this.state.collapsed && tokens2.map(mapTokens)}
					</div>
				</div>
			</div>
		);
	}
}

export default (props: { children: JSX.Element }) => (
	<ErrorBoundary FallbackComponent={withStyles(errorStyle)(FallbackComponent)}>{props.children}</ErrorBoundary>
);
