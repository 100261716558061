import store from 'store';
import { Omit } from 'utility-types';

const keys = {
	redirectPath: 'redirect_path',
	stripeConnectNonce: 'stripe_connect_nonce',
	nonce: 'nonce'
};

interface OwnProps {
	get: (defaultValue?: string) => string;
	set: (value: string) => void;
	remove: () => void;
}

type SessionStore = Omit<StoreJsAPI, keyof OwnProps>;
type SessionStoreProxy = { [k in keyof typeof keys]: OwnProps };

function isOwnProperty(x: any): x is keyof SessionStoreProxy {
	return keys.hasOwnProperty(x);
}

function isStoreProperty(x: any): x is keyof SessionStore {
	return store.hasOwnProperty(x);
}

function getProxyMethods(arg: string) {
	return {
		get: (defaultValue?: string) => store.get(arg, defaultValue),
		set: (value: string) => store.set(arg, value),
		remove: () => store.remove(arg)
	};
}

export default new Proxy({} as SessionStore & SessionStoreProxy, {
	get: (_, key: keyof SessionStore | keyof SessionStoreProxy) => {
		if (isOwnProperty(key)) {
			return getProxyMethods(keys[key]);
		}

		if (isStoreProperty(key)) {
			return store[key];
		}
	}
});
