import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: window.env.FIREBASE_API_KEY, // eslint-disable-line no-undef
	authDomain: window.env.FIREBASE_AUTH_DOMAIN, // eslint-disable-line no-undef
	projectId: window.env.FIREBASE_PROJECT_ID, // eslint-disable-line no-undef
	storageBucket: window.env.FIREBASE_STORAGE_BUCKET, // eslint-disable-line no-undef
	messagingSenderId: window.env.FIREBASE_MESSAGING_ID, // eslint-disable-line no-undef
	appId: window.env.FIREBASE_APP_ID, // eslint-disable-line no-undef
	measurementId: window.env.FIREBASE_MEASUREMENT_ID // eslint-disable-line no-undef
};

const auth = getAuth(initializeApp(firebaseConfig));
export default auth;
