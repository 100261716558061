import { History } from 'history';
import rison from 'rison-node';
import qs from 'querystring';
import { GetListOptions } from '@/graphql/__generated__/types';

function decodeListOptions(params: string) {
	if (!params || params === '!n') {
		return {};
	}

	try {
		return rison.decode_object(params);
	} catch {
		try {
			return rison.decode(params) || {};
		} catch {
			return {};
		}
	}
}

export default (history: History) => {
	const { pathname, search } = history.location;
	const queryParams = qs.parse(search.slice(1));
	const listOptions: GetListOptions['listOptions'][0] = queryParams.q ? decodeListOptions(queryParams.q as string) : {};

	// Add typenames
	const filter = (listOptions.filter || []).map(f => ({ ...f, __typename: 'Filter' }));
	const sort = listOptions.sort ? { ...listOptions.sort, __typename: 'Sort' } : null;

	return {
		// We can get a default service code from uri, but not the whole object
		service: null,
		organization: null,
		location: {
			pathname,
			search,
			from: null,
			__typename: 'RouterLocation'
		},
		listOptions: [
			{
				path: pathname,
				filter,
				sort,
				__typename: 'ListOptions'
			}
		],
		notifications: []
	};
};
