import { IResolvers, IResolverObject, IFieldResolver } from 'graphql-tools';

import merge from 'lodash/merge';
import trim from 'lodash/trim';
import qs from 'querystring';
import { History } from 'history';
import rison from 'rison-node';
import QUERY_AUTHORIZATION from '@/graphql/GetAuthorization.gql';
import QUERY_SERVICE from '@/graphql/GetService.gql';
import QUERY_LISTOPTIONS from '@/graphql/GetListOptions.gql';
import QUERY_LOCATION from '@/graphql/GetLocation.gql';
import QUERY_NOTIFICATIONS from '@/graphql/GetNotifications.gql';
import QUERY_ORGANIZATION from '@/graphql/GetOrganization.gql';
import {
	GetLocation,
	GetAuthorization,
	SetServiceVariables,
	SetOrganizationVariables,
	SetLocationVariables,
	SetListOptionsVariables,
	GetListOptions,
	SetNotificationsVariables,
	GetService
} from '@/graphql/__generated__/types';
import { ApolloCache } from 'apollo-cache';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

const res = (fn: (obj: any, args: any, ctx: any, info: any) => any): IFieldResolver<any, any> => {
	return (obj: any, args: any, ctx: any, info: any) => fn(obj, args, ctx, info);
};

function decodeListOptions(params: string) {
	if (!params || params === '!n') {
		return {};
	}

	try {
		return rison.decode_object(params);
	} catch {
		try {
			return rison.decode(params) || {};
		} catch {
			return {};
		}
	}
}

const uriEncodeListOpts = (str: string) => {
	const opts = decodeListOptions(str);
	const uri = rison.encode_uri(opts) as string;
	return uri.substring(1, uri.length - 1);
};

const getBaseListOptions = (location: GetLocation['location']) => {
	return {
		path: location.pathname,
		sort: null,
		filter: [],
		__typename: 'ListOptions'
	} as GetListOptions['listOptions'][0];
};

interface PatchSearchProps {
	service?: string | null;
	organization?: string | null;
	listOptions?: string | null;
}

function setLocation(history: History, cache: any, location: Partial<GetLocation['location']>) {
	const { location: cachedLocation } = cache.readQuery({ query: QUERY_LOCATION }) as GetLocation;
	const { service } = cache.readQuery({ query: QUERY_SERVICE }) as GetService;

	const searchParams =
		typeof location.search === 'undefined'
			? qs.parse(trim(cachedLocation.search, '?&'))
			: qs.parse(trim(location.search, '?&'));
	const { q: newListOptionsStr, ...newSearchParams } = searchParams;

	newSearchParams.service = service ? service.code : undefined;

	const newSearch = newListOptionsStr
		? `?q=${uriEncodeListOpts(newListOptionsStr as string)}&${qs.stringify(newSearchParams)}`
		: `?${qs.stringify(newSearchParams)}`;

	const newLocation = merge({}, cachedLocation, location, {
		search: newSearch.length > 1 ? newSearch : undefined
	});

	newLocation.from = {
		pathname: cachedLocation.pathname,
		search: cachedLocation.search,
		__typename: 'RouterLocationFlat'
	};

	cache.writeQuery({
		query: QUERY_LOCATION,
		data: { location: newLocation }
	});

	history.push(`${newLocation.pathname}${newLocation.search}`, {
		from: newLocation.from,
		mutation: true
	});
}

function patchSearch(history: History, cache: any, { listOptions, ...other }: PatchSearchProps) {
	const { location: cachedLocation } = cache.readQuery({ query: QUERY_LOCATION }) as GetLocation;

	// Exclude `q` from search, which is in rison format (already uri encoded)
	const { q: oldListOptions, ...currSearch } = qs.parse(trim(cachedLocation.search, '?&'));

	const newSearch = qs.stringify({ ...currSearch, ...other });
	const listOpts = uriEncodeListOpts((listOptions || oldListOptions) as string) || '';

	setLocation(history, cache, { search: `?q=${listOpts}&${newSearch}` });
}

function getListOptionsSearchStr(opts?: GetListOptions['listOptions'][0]) {
	if (!opts) {
		return rison.encode(null);
	}

	const copy = Object.assign({}, opts) as any;

	delete copy.path;

	// Delete __typename so it won't get serialized into url
	delete copy.__typename;
	(copy.filter || []).forEach((f: any) => {
		delete f.__typename;
	});
	if (copy.sort) {
		delete copy.sort.__typename;
	}

	return rison.encode_object(copy);
}

export default (history: History<any>, cache: ApolloCache<NormalizedCacheObject>) => {
	history.listen((location, action) => {
		if (action === 'REPLACE') {
			return;
		}

		const state = location.state || {};

		if (!state.mutation) {
			try {
				const { listOptions } = cache.readQuery({ query: QUERY_LISTOPTIONS }) as GetListOptions;
				const opts = listOptions.find(opt => opt.path === location.pathname);
				const q = getListOptionsSearchStr(opts);
				const newSearch = location.search || `?q=${q}`;
				setLocation(history, cache, { pathname: location.pathname, search: newSearch });
			} catch {
				// Cache not ready
			}
		} else {
			history.replace(`${location.pathname}${location.search}`, { ...state, mutation: false });
		}
	});

	const mutation: IResolverObject = {
		setService: res((_obj, args: SetServiceVariables) => {
			const { authorization } = cache.readQuery({ query: QUERY_AUTHORIZATION }) as GetAuthorization;
			if (authorization && authorization.services) {
				const service = authorization.services.find(s => s !== null && s.code === args.code);
				cache.writeQuery({
					query: QUERY_SERVICE,
					data: { service }
				});
				if (service !== authorization.services[0]) {
					patchSearch(history, cache, { service: args.code });
				}
			}

			return null;
		}),
		setOrganization: res((_obj, args: SetOrganizationVariables) => {
			const { authorization } = cache.readQuery({ query: QUERY_AUTHORIZATION }) as GetAuthorization;
			if (authorization && authorization.organizations) {
				const organization = authorization.organizations.find(s => s !== null && s.name === args.name);

				if (organization) {
					cache.writeQuery({
						query: QUERY_ORGANIZATION,
						data: { organization }
					});

					patchSearch(history, cache, { organization: organization.name });
				}
			}
		}),
		setLocation: res((_obj, { pathname, search }: SetLocationVariables) => {
			const { listOptions } = cache.readQuery({ query: QUERY_LISTOPTIONS }) as GetListOptions;
			const opts = listOptions.find(opt => opt.path === pathname);
			const q = getListOptionsSearchStr(opts);
			const newSearch = typeof search === 'undefined' || search === null ? `?q=${q}` : search;
			setLocation(history, cache, { pathname, search: newSearch });
			return null;
		}),
		setListOptions: res((_obj, { options }: SetListOptionsVariables) => {
			const { listOptions: cachedOptionsArr } = cache.readQuery({
				query: QUERY_LISTOPTIONS
			}) as GetListOptions;
			const { location } = cache.readQuery({ query: QUERY_LOCATION }) as GetLocation;

			const index = cachedOptionsArr.findIndex(o => o.path === location.pathname);
			const cachedOptions = index === -1 ? getBaseListOptions(location) : cachedOptionsArr[index];

			const newOptions = Object.assign({}, cachedOptions, options) as GetListOptions['listOptions'][0];

			newOptions.__typename = 'ListOptions';
			newOptions.filter.forEach(f => {
				f.__typename = 'Filter';
			});
			if (newOptions.sort) {
				newOptions.sort.__typename = 'Sort';
			}

			if (index !== -1) {
				cachedOptionsArr.splice(index, 1, newOptions);
			} else {
				cachedOptionsArr.push(newOptions);
			}

			cache.writeQuery({
				query: QUERY_LISTOPTIONS,
				data: { listOptions: cachedOptionsArr }
			});

			patchSearch(history, cache, { listOptions: getListOptionsSearchStr(newOptions) });
			return null;
		}),
		setNotifications: res((_obj, { notifications }: SetNotificationsVariables) => {
			notifications = notifications.map(notif => ({
				...notif,
				__typename: 'Notification'
			}));
			cache.writeQuery({ query: QUERY_NOTIFICATIONS, data: { notifications } });
			return null;
		})
	};

	return { Mutation: mutation } as IResolvers;
};
